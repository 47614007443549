import { AMMAnalyticsData, StrategyVaultInfo } from '@apps-orangefi/lib/types'
import { BalancesTable } from '@apps-orangefi/ui/molecules/BalancesTable'
import { LPPieChart } from '@apps-orangefi/ui/molecules/LPPieChart'
import { LiquidityDistribution } from '@apps-orangefi/ui/molecules/LiquidityDistribution'
import { PlaceHolderLiquidityData } from '@apps-orangefi/ui/molecules/PlaceHolderLiquidityData'
// @ts-expect-error: Package doesn't support typescript
import univ3prices from '@thanpolas/univ3prices'
import { TickMath } from '@uniswap/v3-sdk'
import { formatUnits, parseEther } from 'viem'

import { padTicksData, TickData } from './LpdfiLiquidityData'

export interface AmmAnalyticsProps extends StrategyVaultInfo, AMMAnalyticsData {
  ticks: {
    upperTick: number | undefined
    lowerTick: number | undefined
  }
}

export const AMMLiquidityData = ({ productInfo }: { productInfo: AmmAnalyticsProps }) => {
  const { baseToken, baseTokenPriceUSD } = productInfo
  if (
    productInfo.ticks.lowerTick === undefined ||
    productInfo.ticks.upperTick === undefined ||
    productInfo.pool === undefined ||
    productInfo.isTokenPairReversed === undefined ||
    productInfo.tick === undefined ||
    productInfo.composition === undefined
  ) {
    return <PlaceHolderLiquidityData />
  }

  const token0Decimals = +productInfo.pool.token0.decimals
  const token1Decimals = +productInfo.pool.token1.decimals
  const token0Name = productInfo.pool.token0.symbol
  const token1Name = productInfo.pool.token1.symbol
  const isToken0Base =
    baseToken.id.toString().toLowerCase() === productInfo.pool.token0.id.toLowerCase()
  const ethPrice = +baseTokenPriceUSD.toNumber() / +productInfo.baseToken.derivedETH
  const quoteTokenPrice = +productInfo.quoteToken.derivedETH * ethPrice
  const token0Price = isToken0Base ? +baseTokenPriceUSD.toNumber() || 0 : quoteTokenPrice || 0
  const token1Price = isToken0Base ? +quoteTokenPrice || 0 : baseTokenPriceUSD.toNumber() || 0

  // Initial liquidity is approximate and will be corrected later
  let liquidity = BigInt('1000000000000000000')

  let token0Balance = +formatUnits(BigInt(productInfo.composition.vaultToken0), token0Decimals)
  let token1Balance = +formatUnits(BigInt(productInfo.composition.vaultToken1), token1Decimals)
  const lpAmount0 = +formatUnits(BigInt(productInfo.composition.poolToken0), token0Decimals)
  const lpAmount1 = +formatUnits(BigInt(productInfo.composition.poolToken1), token1Decimals)
  let lpAmount0Usd = lpAmount0 * token0Price
  let lpAmount1Usd = lpAmount1 * token1Price

  // Swap balances if token pair is reversed
  if (productInfo.isTokenPairReversed) {
    token0Balance = [token1Balance, (token1Balance = token0Balance)][0]
    lpAmount0Usd = [lpAmount1Usd, (lpAmount1Usd = lpAmount0Usd)][0]
  }

  const calculateReadablePrice = (sqrtPrice: string) => {
    return +univ3prices(
      [productInfo.pool!.token0.decimals, productInfo.pool!.token1.decimals],
      sqrtPrice
    ).toAuto()
  }
  let currentPrice: number = calculateReadablePrice(productInfo.pool.sqrtPrice)
  let nextTickPrice = calculateReadablePrice(
    TickMath.getSqrtRatioAtTick(+productInfo.pool.tick + 1).toString()
  )
  const shouldInvertPrice = nextTickPrice < currentPrice
  if (shouldInvertPrice) {
    currentPrice = 1 / currentPrice
  }

  const tickData: TickData[] = []
  for (let tick = productInfo.ticks.lowerTick; tick < productInfo.ticks.upperTick; tick++) {
    const priceLower =
      Math.pow(1.0001, Number(tick)) * 10 ** Number(token0Decimals - token1Decimals)
    const priceUpper =
      Math.pow(1.0001, Number(tick + 1)) * 10 ** Number(token0Decimals - token1Decimals)
    const assets = univ3prices.getAmountsForLiquidityRange(
      productInfo.pool.sqrtPrice.toString(),
      TickMath.getSqrtRatioAtTick(tick).toString(),
      TickMath.getSqrtRatioAtTick(tick + 1).toString(),
      liquidity.toString()
    )
    let amount0 = BigInt(0)
    let amount1 = BigInt(0)
    if (assets.length > 0) {
      amount0 = BigInt(assets[0].toString() || 0)
    }
    if (assets.length > 1) {
      amount1 = BigInt(assets[1].toString() || 0)
    }
    const data: TickData = {
      tickLower: tick,
      tickUpper: tick + 1,
      liquidity: liquidity,
      totalSupply: BigInt(0),
      liquidityUsed: 0,
      liquidityRemaining: Number(liquidity),
      userLiquidity: Number(liquidity),
      shares: BigInt(0),
      price: (priceLower + priceUpper) / 2,
      priceLower,
      priceUpper,
      amount0: +formatUnits(amount0, token0Decimals),
      amount1: +formatUnits(amount1, token1Decimals),
      token0Name,
      token1Name,
    }
    tickData.push(data)
  }

  // Perform correction on liquidity and token amounts in tickData
  const tvlFromTicks = tickData.reduce(
    (sum, tick) => sum + tick.amount0 * token0Price + tick.amount1 * token1Price,
    0
  )
  const liquidityCorrectionFactor = (lpAmount0Usd + lpAmount1Usd) / tvlFromTicks
  if (isFinite(liquidityCorrectionFactor)) {
    liquidity =
      (liquidity * BigInt(+liquidityCorrectionFactor.toFixed(18) * 10 ** 18)) / parseEther('1')
    for (const tick of tickData) {
      tick.amount0 *= liquidityCorrectionFactor
      tick.amount1 *= liquidityCorrectionFactor
      tick.liquidityRemaining = Number(liquidity)
    }
  } else if (tvlFromTicks === 0) {
    liquidity = BigInt(0)
    for (const tick of tickData) {
      tick.amount0 = 0
      tick.amount1 = 0
      tick.liquidityRemaining = 0
    }
  }
  const amount0FromTicks = tickData.reduce((sum, tick) => sum + tick.amount0, 0)
  const amount1FromTicks = tickData.reduce((sum, tick) => sum + tick.amount1, 0)

  const sortedTicks = tickData.sort((a, b) => (a.price - b.price > 0 ? 1 : -1))

  const formattedTicks: TickData[] = padTicksData(
    sortedTicks,
    +productInfo.pool.tick,
    token0Decimals,
    token1Decimals,
    token0Name,
    token1Name
  )

  return (
    <div>
      <div className=" dark:bg-gray-850 p-5 rounded-2xl my-4">
        <p className="mb-4">LP Breakdown</p>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-0">
          <LPPieChart
            token0Name={token0Name}
            token1Name={token1Name}
            token0Usd={amount0FromTicks * token0Price}
            token1Usd={amount1FromTicks * token1Price}
          ></LPPieChart>
          <BalancesTable
            token0Name={token0Name}
            token1Name={token1Name}
            token0Amount={token0Balance}
            token1Amount={token1Balance}
            token0Usd={token0Balance * token0Price}
            token1Usd={token1Balance * token1Price}
            lpBalance={Number(liquidity)}
            lpUsd={amount0FromTicks * token0Price + amount1FromTicks * token1Price}
          />
        </div>
      </div>
      <LiquidityDistribution
        token0Name={token0Name}
        token1Name={token1Name}
        tickData={formattedTicks}
        currentPrice={currentPrice}
        showUtilization={false}
      ></LiquidityDistribution>
    </div>
  )
}
