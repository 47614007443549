import OrangeIcon from '@apps-orangefi/assets/images/logo/logo-symbol.svg'
import WireDistortion from '@apps-orangefi/assets/images/point/wireframe-distortion.svg'
import { BN } from '@apps-orangefi/lib'
import { Task, User } from '@apps-orangefi/lib/types/point'
import { OctagonContainer, Button } from '@apps-orangefi/ui/atoms/point'
import { TaskList } from '@apps-orangefi/ui/molecules/point'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { CSSProperties } from 'react'

const tailwindConfig = require('@apps-orangefi/tailwindcss')

type Props = {
  user: User | undefined
  taskList: Task[]
  points: number
}

const width = 130
const SNAPSHOT_DATE = '2024-08-13 13:00:00'
const snapshotDate = dayjs.tz(SNAPSHOT_DATE, 'GMT')

export const Chapter1Template = ({ user, taskList, points }: Props) => {
  const router = useRouter()

  // TODO: component
  const styleVars = {
    width: `${width}px`,
    position: 'absolute',
    '--spotlight-width': `188px`,
    '--spotlight-height': `77px`,
    '--spotlight-left': `-30px`,
  } as CSSProperties

  return (
    <>
      <div className="flex flex-col w-full pb-24">
        <div className="flex flex-col sm:flex-row gap-3 py-0 items-start">
          <div className="flex flex-col gap-3 w-full md:w-[640px]">
            <OctagonContainer size={6} className="flex flex-row items-center px-3 py-6 sm:p-8">
              <div
                className="border-t border-orange-500 top-0 left-6 active-box"
                style={styleVars}
              ></div>
              <div className="flex flex-row justify-between items-center flex-wrap w-full">
                <div className="flex flex-row justify-start items-baseline type-2xl-semibold">
                  <span className="dark:text-orange-500">Chapter 1.</span>
                  <span className="dark:text-white ml-1">&ldquo;The Countdown&rdquo;</span>
                </div>
                <div className="type-base-medium dark:text-white mt-4 mb-8">
                  Get a point airdrop by signing up and meeting the following protocol criteria at
                  the time of the snapshot.
                </div>
                <div className="flex flex-col sm:flex-row w-full">
                  <OctagonContainer
                    size={3}
                    borderColor={'gray'}
                    className="px-5 py-4 w-fit min-h-[54px] flex items-center"
                  >
                    <div className="type-base-bold dark:text-gray-500">CHAPTER FINISHED</div>
                  </OctagonContainer>
                  {!!user && (!user.parentCode || isEmpty(user.parentCode)) && (
                    <Button
                      label="ADD REF CODE"
                      onSubmit={() => router.push('/')}
                      className="w-fit mt-2 sm:mt-0 sm:ml-3 min-h-[54px]"
                    />
                  )}
                </div>
              </div>
            </OctagonContainer>
            <OctagonContainer size={6} className="flex flex-col items-start px-3 py-6 sm:p-8">
              <div className="type-2xl-semibold dark:text-white">The snapshot was taken</div>
              <div className="flex items-center">
                <div className="type-2xl-semibold dark:text-orange-500 mx-1">
                  {snapshotDate.format('MMMM D[th] [at] ha (UTC)')}.
                </div>
                <a
                  href="https://mirror.xyz/0x6FA2aF9a4d6fFe654361F713780963C10412e7c3/R8LNWvcAIUjtyyiMfWwzdEUw9bUxc62RMjZp7I2jVMw"
                  target="_blank"
                  className="text-xl font-semibold dark:text-gray-500 underline hover:cursor-pointer"
                >
                  Link
                </a>
              </div>
            </OctagonContainer>
          </div>
          <EarnedPoints points={points} />
        </div>
        <div className="mt-8 md:mt-10">
          <TaskList isJoined={!!user} taskList={taskList} snapshotDate={snapshotDate} />
        </div>
      </div>
    </>
  )
}

const EarnedPoints = ({ points }: { points: number }) => {
  return (
    <div className="relative w-full h-full max-w-[508px] max-h-[400px] flex flex-col justify-center">
      <div className="flex flex-col justify-center items-center z-10 h-[286px] md:h-[400px]">
        <div className="text-xl-semibold dark:text-white">You earned</div>
        <div className="dark:bg-orange-300 rounded-full flex justify-between items-center pl-5 pr-3 py-3 mt-3 min-w-[150px]">
          <span className="text-4xl md:text-[48px] leading-8 font-semibold min-w-[50px] text-center">
            {new BN(Math.floor(points)).toFormat()}
          </span>
          <div className="dark:bg-orange-500 w-11 h-11 rounded-full flex items-center justify-center p-2 ml-3 pt-[9px]">
            <Image src={OrangeIcon} width={28} height={28} alt="orange points" />
          </div>
        </div>
      </div>
      <Image
        src={WireDistortion}
        width={508}
        height={400}
        alt="Wireframe Hole"
        className="sm:ml-3 absolute top-0 right-0"
      />
    </div>
  )
}
