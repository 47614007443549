import FigureAdaptiveRangeSetting from '@apps-orangefi/assets/images/features/feature-adaptive-range-setting.svg'
import FigureAutoRebalancing from '@apps-orangefi/assets/images/features/feature-auto-rebalancing.svg'
import FigureDeltaHedge from '@apps-orangefi/assets/images/features/feature-delta-hedge.svg'
import FigureNarrowRange from '@apps-orangefi/assets/images/lpdfi/narrow-range.svg'
import FigureRebalance from '@apps-orangefi/assets/images/lpdfi/rebalance.svg'
import { usePathByChain } from '@apps-orangefi/hooks'
import {
  strategy,
  type ContractProp,
  type StrategyVaultInfo,
  type VaultCapacity,
} from '@apps-orangefi/lib/types'
import { LinkButton } from '@apps-orangefi/ui/atoms/buttons/LinkButton'
import { VaultFeatures } from '@apps-orangefi/ui/molecules'
import { Strategy } from '@apps-orangefi/ui/molecules/lpdfi'
import {
  VaultSummary,
  StrategyVaultDescription,
  Feature,
  ContractWidget,
  VaultInfo,
  MerklRewards,
  Audits,
  Auditor,
} from '@apps-orangefi/ui/molecules/strategy'
import { Analytics } from '@apps-orangefi/ui/organisms/analytics'
import {
  AmmAnalyticsProps,
  AMMLiquidityData,
} from '@apps-orangefi/ui/organisms/analytics/AMMLiquidityData'
import { Tab } from '@headlessui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import type { ContractWidgetProps } from '@apps-orangefi/ui/molecules/strategy/ContractWidget'

export type StrategyVaultTemplateProps = {
  productInfo: AmmAnalyticsProps
  contractProps: ContractProp[]
  contractWidgetProps: ContractWidgetProps
  vaultCapacity: VaultCapacity
}

export const StrategyVaultTemplate = ({
  productInfo,
  contractProps,
  contractWidgetProps,
  vaultCapacity,
}: StrategyVaultTemplateProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const path = usePathByChain()

  return (
    <div className="flex flex-col">
      <VaultSummary
        vaultInfo={productInfo}
        vaultCapacity={vaultCapacity}
        onClickBack={() => router.push(`/${path}`)}
      />
      <div className="flex flex-row container py-8 md:py-12 border-t border-gray-800 md:border-none">
        <div className="flex flex-col w-full lg:mt-0">
          <Tab.Group>
            <Tab.List className="w-full flex justify-start">
              <Tab>
                {({ selected }) => (
                  <div
                    className={`w-fit type-base-medium dark:text-white px-3 py-2 md:py-3 md:px-4 flex flex-row items-center hover:opacity-80 rounded-lg md:rounded-2xl ${
                      selected ? 'dark:bg-gray-750' : 'dark:bg-gray-850'
                    }`}
                  >
                    <span>Overview</span>
                  </div>
                )}
              </Tab>
              <Tab className="ml-3">
                {({ selected }) => (
                  <div
                    className={`w-fit type-base-medium dark:text-white px-3 py-2 md:py-3 md:px-4 flex flex-row items-center hover:opacity-80 rounded-lg md:rounded-2xl ${
                      selected ? 'dark:bg-gray-750' : 'dark:bg-gray-850'
                    }`}
                  >
                    <span>Analytics</span>
                  </div>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-8 md:mt-10">
              <Tab.Panel>
                <div className="flex flex-row flex-wrap lg:flex-nowrap flex-row-reverse">
                  <div className="flex flex-col box-content md:ml-20 w-full md:max-w-[440px]">
                    {/* TODO: replace ContractWidgetContainer */}
                    <ContractWidget
                      contractWidgetProps={contractWidgetProps}
                      productInfo={productInfo}
                    />
                    {/* <MerklRewards rewardsApr={productInfo.rewardsApr} className="mt-3" /> */}
                    {/* <Audits auditors={[Auditor.WatchPug, Auditor.Zokyo]} className="mt-3" />
                    <VaultInfo contractProps={contractProps} className="mt-3" /> */}
                  </div>
                  <div className="flex flex-col w-full mt-8 md:mt-0">
                    <StrategyVaultDescription
                      info={productInfo.info}
                      altOverview="The liquidity provided through this vault is utilized for AMM to generate swap fee revenue, with the range managed by on automated strategy bot."
                    />
                    <LinkButton
                      label={t('DOCS.ACTION.READ_MORE')}
                      href="https://app.gitbook.com/o/W3VCC44gtiSmmPkMk2QP/s/UqoeXETnSVXtG6WENmvg/liquidity-vaults/pegged-pair-vaults#pegged-pair-strategy"
                      className="mt-5"
                    />
                    <section className="pt-10">
                      <VaultFeatures
                        allocation={productInfo.allocation}
                        version={productInfo.info.version}
                        className="w-full"
                      />
                    </section>

                    <section className="pt-10">
                      <h2 className="type-2xl-semibold dark:text-white">
                        {productInfo.info.strategy === strategy.PeggedPair
                          ? t('DOCS.STRATEGY_FEATURES.TITLE.PEGGED')
                          : t('DOCS.STRATEGY_FEATURES.TITLE.COMMON')}
                      </h2>
                      {productInfo.info.strategy === strategy.PeggedPair ? (
                        <div className="flex flex-row gap-3 mt-6">
                          <Strategy
                            title={t('DOCS.STRATEGY_FEATURES.TOPIC_1.3.TITLE')}
                            description={t('DOCS.STRATEGY_FEATURES.TOPIC_1.2.DESC')}
                            figureSrc={FigureNarrowRange}
                            readMoreLink="https://app.gitbook.com/o/W3VCC44gtiSmmPkMk2QP/s/UqoeXETnSVXtG6WENmvg/liquidity-vaults/pegged-pair-vaults#pegged-pair-strategy"
                            className="w-1/2"
                          />
                          <Strategy
                            title={t('DOCS.STRATEGY_FEATURES.TOPIC_2.1.TITLE')}
                            description={t('DOCS.STRATEGY_FEATURES.TOPIC_2.1.DESC')}
                            figureSrc={FigureRebalance}
                            readMoreLink="https://app.gitbook.com/o/W3VCC44gtiSmmPkMk2QP/s/UqoeXETnSVXtG6WENmvg/liquidity-vaults/pegged-pair-vaults#pegged-pair-strategy"
                            className="w-1/2"
                          />
                        </div>
                      ) : (
                        <div className="flex flex-col gap-3 mt-6">
                          <Feature
                            title={t('DOCS.STRATEGY_FEATURES.DN_VAULT.1.TITLE')}
                            description={t('DOCS.STRATEGY_FEATURES.DN_VAULT.1.DESC')}
                            figureSrc={FigureDeltaHedge}
                            readMoreLink="https://orange-finance.gitbook.io/orange-finance/orange-academy/delta-neutral-strategy"
                          />
                          <Feature
                            title={t('DOCS.STRATEGY_FEATURES.DN_VAULT.2.TITLE')}
                            description={t('DOCS.STRATEGY_FEATURES.DN_VAULT.2.DESC')}
                            figureSrc={FigureAutoRebalancing}
                            readMoreLink="https://orange-finance.gitbook.io/orange-finance/orange-academy/how-orange-finance-works#auto-rebalancing"
                          />
                          <Feature
                            title={t('DOCS.STRATEGY_FEATURES.DN_VAULT.3.TITLE')}
                            description={t('DOCS.STRATEGY_FEATURES.DN_VAULT.3.DESC')}
                            figureSrc={FigureAdaptiveRangeSetting}
                            readMoreLink="https://orange-finance.gitbook.io/orange-finance/orange-academy/how-orange-finance-works"
                          />
                        </div>
                      )}
                    </section>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <Analytics>
                  <p></p>
                  <AMMLiquidityData productInfo={productInfo} />
                </Analytics>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  )
}
